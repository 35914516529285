<template>
  <div
    ref="tableScrollerElement"
    class="table-scroller"
    :id="`${tableId ? `${tableId}--table-scroller` : 'table-scroller'}`"
  >
    <Spinner
      v-show="loading"
      class="table-spinner"
    />
    <EmptyState
      v-if="!loading && !rows.length"
      class="empty-state"
      :message="noDataMessage"
    />
    <div
      :id="tableId || 'table'"
      :class="[
        'table',
        `${hoveredResizeEdge && 'resize-edge-hovered'}`,
        `${clickedResizeEdge && 'resize-edge-clicked'}`
      ]"
      :style="{
        'grid-template-columns': `${gridTemplateColumnsWidth}`,
        'grid-template-rows': `${gridTemplateRowsHeight}`
      }"
    >
      <div
        class="table-head"
      >
        <div class="table-row">
          <div
            v-if="selectable && !hasDpsTraderRole"
            class="table-cell table-cell-header table-cell--selected"
          >
            <CheckboxDropdown
              :actions="headerCheckboxActions"
              :disabled="loading || !selectableRows.length"
              :selected-items-length="selectedEvents.length"
              :rows-length="selectableRows.length"
              @selected:value="(value) => bulkUpdateRowSelection(value)"
            />
          </div>
          <div
            v-for="(column, i) in visibleColumns"
            :key="column.key"
            :class="[
              'table-cell',
              'table-cell-header',
              `table-cell--${column.key}`,
              `${hoveredTableHeaderKey === column.key && 'hovered'}`,
              `${column.key === selectedSort.field && 'active'}`
            ]"
            :style="{ zIndex: 100 + visibleColumns.length - i }"
            @mouseover="tableHeaderHovered(true, column.key)"
            @mouseleave="tableHeaderHovered(false, column.key)"
          >
            <Tooltip
              v-if="column.tooltipText"
              :text="column.tooltipText"
            >
              <span class="table-cell-header--text">{{ column.label }}</span>
            </Tooltip>
            <span
              v-else
              class="table-cell-header--text"
            >
              {{ column.label }}
            </span>

            <Tooltip :text="getSortTooltipText(column, selectedSort)">
              <div
                v-if="column.sortable"
                class="icon-container"
                @click="updateSorting(column)"
              >
                <Icon :name="getSortIcon(column, selectedSort)" />
              </div>
            </Tooltip>
            <div
              v-if="!column.nonResizable && !loading"
              :class="[
                'resize-edge',
                `${hoveredResizeEdge === column.key && 'hovered'}`,
                `${clickedResizeEdge === column.key && 'clicked'}`
              ]"
              :style="{ height: `${resizeLineHeight}px` }"
              @mouseover="resizeEdgeHovered(true, column.key)"
              @mouseleave="resizeEdgeHovered(false, column.key)"
              @mousedown="resizeEdgeClicked($event, true, column.key)"
              @mouseup="resizeEdgeClicked($event, false, column.key)"
            >
              <Icon
                class="icon"
                name="resize-left-right"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="table-body"
        class="table-body"
      >
        <TableRow
          v-for="(row, index) in rows"
          :row="row"
          :visible-columns="visibleColumns"
          :is-selected="isRowSelected(row)"
          :key="row?.playerId || index"
          :aria-rowindex="index"
          :has-dps-trader-role="hasDpsTraderRole"
          :class="[selectedRowSpacingOption]"
          @click.stop="emitRowClicked(row)"
          @row-selected="updateRowSelection(row, true)"
          @row-unselected="updateRowSelection(row, false)"
          @row-context-menu="emitContextMenu"
        >
          <template #cell="{ column }">
            <slot
              name="cell"
              :column="column"
              :row="row"
            >
              {{ row[column.key] }}
            </slot>
          </template>
        </TableRow>
      </div>
    </div>
  </div>
</template>

<script>
import {
  filter,
  find,
  each,
  map,
  throttle,
} from 'lodash';
import {
  provide, computed, ref, onMounted, onBeforeUnmount, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useScroll } from '@vueuse/core';
import Spinner from './Spinner';
import CheckboxDropdown from './CheckboxDropdown';
import TableRow from './TableRow';
import Icon from './Icon';
import Tooltip from '@/components/common/Tooltip';
import EmptyState from '@/components/common/EmptyState';
import {
  getSortTooltipText,
  getSortIcon,
  getColumnCurrentWidth,
  resizeColumn,
  generateGridTemplateColumns,
  generateGridTemplateColumnsWidth,
  generateGridTemplateRowsHeight,
} from './table-helper';

export default {
  components: {
    Spinner,
    CheckboxDropdown,
    TableRow,
    Icon,
    Tooltip,
    EmptyState,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    tableId: {
      type: String,
      default: '',
    },
    noDataMessage: {
      type: String,
      default: 'No available events',
    },
  },
  emits: {
    'row-clicked': {
      type: Object,
    },
    'row-selected': {
      type: Object,
    },
    'row-unselected': {
      type: Object,
    },
    'row-context-menu': {
      type: Object,
    },
    scroll: {},
  },
  setup(props, { emit }) {
    const store = useStore();
    provide('selectable', props.selectable);
    const hasDpsTraderRole = computed(() => store.getters.isDpsTraderRole);
    const visibleColumns = computed(() => filter(props.columns, { visible: true }));
    const selectableRows = computed(() => filter(props.rows, { selectable: true }));
    const isRowSelected = (row) => !!find(props.selectedRows, { eventId: row.eventId });
    const selectedRowSpacingOption = computed(() => store.getters.selectedRowSpacingOption);
    const gridTemplateColumns = ref(generateGridTemplateColumns(visibleColumns.value, props.selectable));
    const gridTemplateColumnsWidth = computed(() => generateGridTemplateColumnsWidth(gridTemplateColumns.value));
    const gridTemplateRowsHeight = computed(() => generateGridTemplateRowsHeight(props.rows.length, selectedRowSpacingOption.value));
    const headerCheckboxActions = computed(() => [
      { key: 'active', label: 'Active', disabled: !find(props.rows, (event) => !event.isSuspended && event.booked) },
      { key: 'suspended', label: 'Suspended', disabled: !find(props.rows, (event) => event.isSuspended) },
      { key: 'not-booked', label: 'Not booked', disabled: !find(props.rows, (event) => !event.booked) },
    ]);
    const selectedEvents = computed(() => store.getters.selectedEvents);

    // SORT
    const hoveredTableHeaderKey = ref(null);
    const selectedSort = computed(() => store.getters.eventListAppliedSort);

    // COLUMN RESIZE
    const hoveredResizeEdge = ref(null);
    const clickedResizeEdge = ref(null);
    const resizeEdgeXPosition = ref(null);
    const resizeColumnInitialWidth = ref(null);
    const resizedColumns = ref([]);
    const resizeLineHeight = ref(0);

    const emitRowClicked = (row, event) => {
      if (event && event?.keyCode !== 13) return;
      emit('row-clicked', row);
    };
    const updateRowSelection = (row, value) => {
      emit(`${value ? 'row-selected' : 'row-unselected'}`, row);
    };
    const bulkUpdateRowSelection = (value) => {
      if (!value) {
        each(
          props.rows,
          (row) => updateRowSelection(row, false),
        );
        store.dispatch('updateSelectedEvents', []);
        return;
      }
      let eventsToSuspend = [];
      if (value === 'all') {
        if (selectedEvents.value.length === selectableRows.value.length) {
          eventsToSuspend = [];
        } else {
          eventsToSuspend = props.rows;
        }
      } else if (value === 'active') {
        eventsToSuspend = filter(props.rows, (row) => !row.isSuspended && row.booked);
      } else if (value === 'suspended') {
        eventsToSuspend = filter(props.rows, (row) => row.isSuspended);
      } else if (value === 'not-booked') {
        eventsToSuspend = filter(props.rows, (row) => !row.booked);
      }
      store.dispatch('updateSelectedEvents', map(eventsToSuspend, (row) => (
        {
          eventId: row.eventId, sportId: row.sportId, booked: row.booked, isSuspended: row.isSuspended, matchState: row.matchState,
        }
      )));
    };

    // SORT ACTIONS
    const updateSorting = (sort) => {
      if (!sort || !selectedSort.value) return;
      const field = sort.key;
      let order = 'ASC';
      if (sort.key === selectedSort.value.field) {
        const currentOrder = selectedSort.value.order;
        order = currentOrder === 'ASC' ? 'DESC' : 'ASC';
      }
      const updatedSort = {
        field,
        order,
      };
      store.dispatch('updateSort', updatedSort);
    };

    // COLUMN RESIZE ACTIONS
    const resizeEdgeHovered = (isResized, headerKey) => {
      hoveredResizeEdge.value = isResized ? headerKey : null;
    };

    const setReziseLineHeight = () => {
      resizeLineHeight.value = 44; // Initial height of the table header cell
      const tableContentHeight = document.getElementById(props.tableId || 'table').clientHeight;
      if (tableContentHeight) { resizeLineHeight.value = tableContentHeight; }
    };

    const tableHeaderHovered = (isHovered, headerKey) => {
      hoveredTableHeaderKey.value = isHovered ? headerKey : null;
      setReziseLineHeight();
    };

    const resetResize = () => {
      resizeEdgeXPosition.value = null;
      clickedResizeEdge.value = null;
      resizeColumnInitialWidth.value = null;
    };

    const resizeEdgeClicked = (event, isClicked, headerKey) => {
      if (isClicked) {
        resizeEdgeXPosition.value = event.clientX;
        clickedResizeEdge.value = headerKey;
        const tableCellClass = `table-cell--${headerKey}`;
        resizeColumnInitialWidth.value = getColumnCurrentWidth(tableCellClass);
      } else {
        resetResize();
      }
    };

    const onResizeMouseMove = (e) => {
      if (!clickedResizeEdge.value) return;
      const mouseXPositionDifference = e.clientX - resizeEdgeXPosition.value;
      resizeColumn(
        gridTemplateColumns.value,
        clickedResizeEdge.value,
        mouseXPositionDifference,
        resizeColumnInitialWidth.value,
        resetResize,
        props.tableId,
      );
    };
    onMounted(() => {
      const table = document.getElementById(props.tableId || 'table');
      table.addEventListener('mousemove', (e) => onResizeMouseMove(e));
      table.addEventListener('mouseup', resetResize);
      table.addEventListener('mouseleave', resetResize);
    });

    onBeforeUnmount(() => {
      const table = document.getElementById(props.tableId || 'table');
      table.removeEventListener('mousemove', (e) => onResizeMouseMove(e));
      table.removeEventListener('mouseup', resetResize);
      table.removeEventListener('mouseleave', resetResize);
    });

    watch(visibleColumns, () => {
      gridTemplateColumns.value = generateGridTemplateColumns(visibleColumns.value, props.selectable);
    });

    const emitContextMenu = (data) => {
      emit('row-context-menu', data);
    };

    const tableScrollerElement = ref(null);
    const { isScrolling } = useScroll(tableScrollerElement);
    provide('isTableScrolling', isScrolling);
    watch(isScrolling, throttle(() => {
      if (!isScrolling.value) return;
      emit('scroll');
    }, 100));

    return {
      tableScrollerElement,
      visibleColumns,
      selectableRows,
      isRowSelected,
      emitRowClicked,
      updateRowSelection,
      bulkUpdateRowSelection,
      tableHeaderHovered,
      hoveredTableHeaderKey,
      selectedSort,
      updateSorting,
      getSortTooltipText,
      getSortIcon,
      resizeEdgeHovered,
      hoveredResizeEdge,
      clickedResizeEdge,
      resizeEdgeClicked,
      hasDpsTraderRole,
      selectedRowSpacingOption,
      headerCheckboxActions,
      selectedEvents,
      resizedColumns,
      gridTemplateColumnsWidth,
      gridTemplateRowsHeight,
      resizeLineHeight,
      emitContextMenu,
    };
  },
};
</script>

<style lang="scss">
.table-scroller {
  position: relative;
  overflow-y: auto;

  .table-spinner {
    background-color: transparentize($white, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .empty-state {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.table {
  width: 100%;
  display: grid;
  &.resize-edge-hovered {
    cursor: grab;
  }
  &.resize-edge-clicked {
    cursor: grabbing;
  }

  .table-row {
    display: contents;
    justify-content: flex-start;
    border-bottom: $tableRowBorder;
    outline: none;
  }

  .table-cell {
    flex: 1;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: $tableCellPadding;
    // background: #fff;

    &.table-cell--selected {
      .checkbox-dropdown--root {
        margin-left: -4px;
      }
    }

    & .resize-edge {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 8px;
      width: 3px;
      z-index: 2;
      & .icon {
        position: absolute;
        top: 21px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        svg path:nth-child(2) {
          fill: $gray500;
        }
      }

      &.hovered {
        opacity: 1;
        background-color: $gray500;

        & .icon {
          svg path:nth-child(2) {
            fill: $gray500;
          }
        }
      }

      &.clicked {
        opacity: 1;
        background-color: $gray800;

        & .icon {
          svg path:nth-child(2) {
            fill: $gray800;
          }
        }
      }
    }
  }

  .table-cell-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: $gray300;

    & .icon-container {
      display: none;
      padding: .3rem;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: $gray400;
      }
    }

    &.active {
      & .icon-container {
        background-color: $gray400;
      }
    }

    & .tooltip {
      display: flex;
      align-items: center;
      .tooltip-element {
        display: flex;
        align-items: center;
      }
    }

    & span.table-cell-header--text {
      display: inline-block;
      width: 100%;
      color: $gray800 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.hovered, &.active {
      & .icon-container {
        display: block;
      }
    }
  }

  .table-head {
    display: contents;
    background-color: $gray300;
    color: $tableHeadTextColor;
    border-bottom: $tableHeadRow;
    .table-row {
      display: contents;
      padding: $tableHeadRowPadding;
    }
  }

  .table-body {
    display: contents;
    .table-row {
      margin: $tableBodyRowMargin;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
</style>
